<div class="card museum ${hover? 'hover': ''} index_${index}"
     id="museum_${museum.id}"
     ref="cardComponent"
     mouseover.trigger="highlightMarker(true)"
     mouseleave.trigger="highlightMarker(false)">
    <a href="${ museum.getAbsoluteUrl }">
        <img src="${ museum.image }"
             class="card-img-top"
             alt="">
    </a>
        <div class="card-body">
            <span>
                <h4 class="card-title mb-0 text-4 font-weight-bold">${ museum.name }</h4>
                <p class="card-text mb-1 pb-0 text-3">${ museum.address } ${ museum.zipCode } ${ museum.city }</p>
                <p class="card-text text-3 mb-0">${ museum.shortDescription }</p>
                <div class="meta-nav">
                    <a href="${ museum.getAbsoluteUrl }" class="read-more text-color-primary font-weight-semibold text-1">Mehr Infos <i class="fas fa-angle-right position-relative top-1 ms-1"></i></a>
                </div>
            </span>
            <i class="fas fa-map-marker-alt"  click.trigger="zoomToMarker()"></i>
        </div>

</div>


<div class="map-overlay drf-marker ${hover? 'hover': ''}"
     mouseover.trigger="highlightMarker(true)"
     mouseleave.trigger="highlightMarker(false)"
     click.trigger="markerClicked()"
    ref="markerComponent">
    <i class="fas fa-map-marker-alt ${hover? 'hover': ''}"></i>

    <div class="anchor ${reveal? 'reveal':''}">
        <i style="margin-top: 22px;" class="fas fa-caret-up"></i>
        <div class="info-component">
            <div class="tabs-header">
                <i click.delegate="close()" class="fas fa-times"></i>
                <div class="tab ${activeTab === 'adresse'? 'active':''}"
                     click.delegate="showTab('adresse')">
                    Adresse
                </div>
                <div class="tab ${activeTab === 'opening'? 'active':''}"
                     click.delegate="showTab('opening')">
                    Öffnungszeiten
                </div>
            </div>
            <div class="tabs-body" show.bind="activeTab === 'adresse'">
                ${ museum.name }<br>
                ${ museum.address }<br>
                ${ museum.zipCode } ${ museum.city }
                <div class="more-infos mt-2" click.delegate="goToDetail(museum.getAbsoluteUrl)">
                    <a href="${ museum.getAbsoluteUrl }" class="read-more text-color-primary font-weight-semibold text-1">Mehr Infos <i class="fas fa-angle-right position-relative top-1 ms-1"></i></a>
                </div>
            </div>
            <div class="tabs-body" show.bind="activeTab === 'opening'">
                ${ museum.shortDescription }
            </div>
        </div>
    </div>
</div>
