/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageUrl,
    ImageUrlFromJSON,
    ImageUrlFromJSONTyped,
    ImageUrlToJSON,
    ProjectCategory,
    ProjectCategoryFromJSON,
    ProjectCategoryFromJSONTyped,
    ProjectCategoryToJSON,
    ProjectClient,
    ProjectClientFromJSON,
    ProjectClientFromJSONTyped,
    ProjectClientToJSON,
    ProjectLocation,
    ProjectLocationFromJSON,
    ProjectLocationFromJSONTyped,
    ProjectLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    readonly description: string;
    /**
     * 
     * @type {Array<ProjectCategory>}
     * @memberof Project
     */
    readonly categories: Array<ProjectCategory>;
    /**
     * 
     * @type {ProjectClient}
     * @memberof Project
     */
    readonly client: ProjectClient | null;
    /**
     * 
     * @type {ProjectLocation}
     * @memberof Project
     */
    readonly location: ProjectLocation | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    longitude?: number | null;
    /**
     * 
     * @type {Array<ImageUrl>}
     * @memberof Project
     */
    gallery: Array<ImageUrl>;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'categories': ((json['categories'] as Array<any>).map(ProjectCategoryFromJSON)),
        'client': ProjectClientFromJSON(json['client']),
        'location': ProjectLocationFromJSON(json['location']),
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'gallery': ((json['gallery'] as Array<any>).map(ImageUrlFromJSON)),
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
        'gallery': ((value.gallery as Array<any>).map(ImageUrlToJSON)),
    };
}

