import * as __au2ViewDef from './project-detail.html';
import { customElement } from '@aurelia/runtime-html';
import {IDialogController, inject} from "aurelia";
import {Project} from "../../api";

@customElement(__au2ViewDef)
@inject(IDialogController, Element)
export class ProjectDetail {

    project: Project;
    controller: IDialogController;
    host: Element;

    constructor(dialog: IDialogController, host: Element) {
        // change some settings
        this.controller = dialog;
        this.host = host;
    }

    attaching() {
        document.body.classList.add("dialog-open");

        const animation = this.host.animate(
            [{ opacity: '0' }, { opacity: '1' }],
            { duration: 200 },
        );
        return animation.finished;
    }

    detaching() {
        document.body.classList.remove("dialog-open");

        const animation = this.host.animate(
            [{ opacity: '1' }, { opacity: '0' }],
            { duration: 200 },
        );
        return animation.finished;
    }

    activate(model) {
        this.project = model
    }

    close() {
        this.controller.ok()
    }
}
