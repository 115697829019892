/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageUrl,
    ImageUrlFromJSON,
    ImageUrlFromJSONTyped,
    ImageUrlToJSON,
    ProjectCategory,
    ProjectCategoryFromJSON,
    ProjectCategoryFromJSONTyped,
    ProjectCategoryToJSON,
    ProjectClient,
    ProjectClientFromJSON,
    ProjectClientFromJSONTyped,
    ProjectClientToJSON,
    ProjectLocation,
    ProjectLocationFromJSON,
    ProjectLocationFromJSONTyped,
    ProjectLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PatchedProject
 */
export interface PatchedProject {
    /**
     * 
     * @type {number}
     * @memberof PatchedProject
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedProject
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedProject
     */
    readonly description?: string;
    /**
     * 
     * @type {Array<ProjectCategory>}
     * @memberof PatchedProject
     */
    readonly categories?: Array<ProjectCategory>;
    /**
     * 
     * @type {ProjectClient}
     * @memberof PatchedProject
     */
    readonly client?: ProjectClient | null;
    /**
     * 
     * @type {ProjectLocation}
     * @memberof PatchedProject
     */
    readonly location?: ProjectLocation | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedProject
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedProject
     */
    longitude?: number | null;
    /**
     * 
     * @type {Array<ImageUrl>}
     * @memberof PatchedProject
     */
    gallery?: Array<ImageUrl>;
}

export function PatchedProjectFromJSON(json: any): PatchedProject {
    return PatchedProjectFromJSONTyped(json, false);
}

export function PatchedProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(ProjectCategoryFromJSON)),
        'client': !exists(json, 'client') ? undefined : ProjectClientFromJSON(json['client']),
        'location': !exists(json, 'location') ? undefined : ProjectLocationFromJSON(json['location']),
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'gallery': !exists(json, 'gallery') ? undefined : ((json['gallery'] as Array<any>).map(ImageUrlFromJSON)),
    };
}

export function PatchedProjectToJSON(value?: PatchedProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
        'gallery': value.gallery === undefined ? undefined : ((value.gallery as Array<any>).map(ImageUrlToJSON)),
    };
}

