/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Museum,
    MuseumFromJSON,
    MuseumToJSON,
    PaginatedMuseumList,
    PaginatedMuseumListFromJSON,
    PaginatedMuseumListToJSON,
    PatchedMuseum,
    PatchedMuseumFromJSON,
    PatchedMuseumToJSON,
} from '../models';

export interface MuseumsCreateRequest {
    museum: Museum;
}

export interface MuseumsDestroyRequest {
    id: number;
}

export interface MuseumsListRequest {
    cafeteria?: boolean;
    category?: string;
    childrenAndFamilyFriendly?: boolean;
    fullyWheelchairAccessible?: boolean;
    isMuseumAssociationMember?: boolean;
    location?: string;
    name?: string;
    page?: number;
    parking?: boolean;
    partiallyWheelchairAccessible?: boolean;
    publicTransportationAccessible?: boolean;
    restaurant?: boolean;
    shop?: boolean;
}

export interface MuseumsPartialUpdateRequest {
    id: number;
    patchedMuseum?: PatchedMuseum;
}

export interface MuseumsRetrieveRequest {
    id: number;
}

export interface MuseumsUpdateRequest {
    id: number;
    museum: Museum;
}

/**
 * 
 */
export class MuseumsApi extends runtime.BaseAPI {

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsCreateRaw(requestParameters: MuseumsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Museum>> {
        if (requestParameters.museum === null || requestParameters.museum === undefined) {
            throw new runtime.RequiredError('museum','Required parameter requestParameters.museum was null or undefined when calling museumsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/museums/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MuseumToJSON(requestParameters.museum),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MuseumFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsCreate(requestParameters: MuseumsCreateRequest, initOverrides?: RequestInit): Promise<Museum> {
        const response = await this.museumsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsDestroyRaw(requestParameters: MuseumsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling museumsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/museums/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsDestroy(requestParameters: MuseumsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.museumsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsListRaw(requestParameters: MuseumsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedMuseumList>> {
        const queryParameters: any = {};

        if (requestParameters.cafeteria !== undefined) {
            queryParameters['cafeteria'] = requestParameters.cafeteria;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.childrenAndFamilyFriendly !== undefined) {
            queryParameters['children_and_family_friendly'] = requestParameters.childrenAndFamilyFriendly;
        }

        if (requestParameters.fullyWheelchairAccessible !== undefined) {
            queryParameters['fully_wheelchair_accessible'] = requestParameters.fullyWheelchairAccessible;
        }

        if (requestParameters.isMuseumAssociationMember !== undefined) {
            queryParameters['is_museum_association_member'] = requestParameters.isMuseumAssociationMember;
        }

        if (requestParameters.location !== undefined) {
            queryParameters['location'] = requestParameters.location;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.parking !== undefined) {
            queryParameters['parking'] = requestParameters.parking;
        }

        if (requestParameters.partiallyWheelchairAccessible !== undefined) {
            queryParameters['partially_wheelchair_accessible'] = requestParameters.partiallyWheelchairAccessible;
        }

        if (requestParameters.publicTransportationAccessible !== undefined) {
            queryParameters['public_transportation_accessible'] = requestParameters.publicTransportationAccessible;
        }

        if (requestParameters.restaurant !== undefined) {
            queryParameters['restaurant'] = requestParameters.restaurant;
        }

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/museums/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMuseumListFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsList(requestParameters: MuseumsListRequest, initOverrides?: RequestInit): Promise<PaginatedMuseumList> {
        const response = await this.museumsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsPartialUpdateRaw(requestParameters: MuseumsPartialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Museum>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling museumsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/museums/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMuseumToJSON(requestParameters.patchedMuseum),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MuseumFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsPartialUpdate(requestParameters: MuseumsPartialUpdateRequest, initOverrides?: RequestInit): Promise<Museum> {
        const response = await this.museumsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsRetrieveRaw(requestParameters: MuseumsRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Museum>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling museumsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/museums/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MuseumFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsRetrieve(requestParameters: MuseumsRetrieveRequest, initOverrides?: RequestInit): Promise<Museum> {
        const response = await this.museumsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsUpdateRaw(requestParameters: MuseumsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Museum>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling museumsUpdate.');
        }

        if (requestParameters.museum === null || requestParameters.museum === undefined) {
            throw new runtime.RequiredError('museum','Required parameter requestParameters.museum was null or undefined when calling museumsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/museums/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MuseumToJSON(requestParameters.museum),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MuseumFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows museums to be viewed or edited.
     */
    async museumsUpdate(requestParameters: MuseumsUpdateRequest, initOverrides?: RequestInit): Promise<Museum> {
        const response = await this.museumsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
