/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedProjectCategory
 */
export interface PatchedProjectCategory {
    /**
     * 
     * @type {number}
     * @memberof PatchedProjectCategory
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectCategory
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectCategory
     */
    readonly description?: string;
}

export function PatchedProjectCategoryFromJSON(json: any): PatchedProjectCategory {
    return PatchedProjectCategoryFromJSONTyped(json, false);
}

export function PatchedProjectCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedProjectCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function PatchedProjectCategoryToJSON(value?: PatchedProjectCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

