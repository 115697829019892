/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedProjectLocation
 */
export interface PatchedProjectLocation {
    /**
     * 
     * @type {number}
     * @memberof PatchedProjectLocation
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectLocation
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectLocation
     */
    readonly description?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedProjectLocation
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedProjectLocation
     */
    longitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedProjectLocation
     */
    zoom?: number | null;
}

export function PatchedProjectLocationFromJSON(json: any): PatchedProjectLocation {
    return PatchedProjectLocationFromJSONTyped(json, false);
}

export function PatchedProjectLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedProjectLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'zoom': !exists(json, 'zoom') ? undefined : json['zoom'],
    };
}

export function PatchedProjectLocationToJSON(value?: PatchedProjectLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
        'zoom': value.zoom,
    };
}

