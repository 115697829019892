/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginatedProjectClientList,
    PaginatedProjectClientListFromJSON,
    PaginatedProjectClientListToJSON,
    PatchedProjectClient,
    PatchedProjectClientFromJSON,
    PatchedProjectClientToJSON,
    ProjectClient,
    ProjectClientFromJSON,
    ProjectClientToJSON,
} from '../models';

export interface ProjectClientsCreateRequest {
    projectClient?: ProjectClient;
}

export interface ProjectClientsDestroyRequest {
    id: number;
}

export interface ProjectClientsListRequest {
    page?: number;
}

export interface ProjectClientsPartialUpdateRequest {
    id: number;
    patchedProjectClient?: PatchedProjectClient;
}

export interface ProjectClientsRetrieveRequest {
    id: number;
}

export interface ProjectClientsUpdateRequest {
    id: number;
    projectClient?: ProjectClient;
}

/**
 * 
 */
export class ProjectClientsApi extends runtime.BaseAPI {

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsCreateRaw(requestParameters: ProjectClientsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectClient>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-clients/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectClientToJSON(requestParameters.projectClient),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectClientFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsCreate(requestParameters: ProjectClientsCreateRequest, initOverrides?: RequestInit): Promise<ProjectClient> {
        const response = await this.projectClientsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsDestroyRaw(requestParameters: ProjectClientsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectClientsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-clients/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsDestroy(requestParameters: ProjectClientsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.projectClientsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsListRaw(requestParameters: ProjectClientsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedProjectClientList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-clients/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedProjectClientListFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsList(requestParameters: ProjectClientsListRequest, initOverrides?: RequestInit): Promise<PaginatedProjectClientList> {
        const response = await this.projectClientsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsPartialUpdateRaw(requestParameters: ProjectClientsPartialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectClientsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-clients/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedProjectClientToJSON(requestParameters.patchedProjectClient),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectClientFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsPartialUpdate(requestParameters: ProjectClientsPartialUpdateRequest, initOverrides?: RequestInit): Promise<ProjectClient> {
        const response = await this.projectClientsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsRetrieveRaw(requestParameters: ProjectClientsRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectClientsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-clients/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectClientFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsRetrieve(requestParameters: ProjectClientsRetrieveRequest, initOverrides?: RequestInit): Promise<ProjectClient> {
        const response = await this.projectClientsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsUpdateRaw(requestParameters: ProjectClientsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectClientsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-clients/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectClientToJSON(requestParameters.projectClient),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectClientFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project clients to be viewed or edited.
     */
    async projectClientsUpdate(requestParameters: ProjectClientsUpdateRequest, initOverrides?: RequestInit): Promise<ProjectClient> {
        const response = await this.projectClientsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
