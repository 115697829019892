import {CustomMarkerClassOptions, MarkerController} from "./interfaces";
// @ts-ignore
import {} from 'googlemaps';


export function customMarkerClassLoader() {
  return class CustomMarkerClass extends google.maps.OverlayView {

    controller: MarkerController;
    element: HTMLElement;
    opts: CustomMarkerClassOptions;

    constructor(opts: CustomMarkerClassOptions) {
      super();
      this.controller = opts.controller;
      this.setMap(opts.map);
      this.opts = opts;
      this.setMap(this.opts.map);
      this.controller.marker = this;
    }

    draw(): void {
      if (!this.element) {
        this.element = this.opts.element as HTMLElement;
        this.element.style.position = 'absolute';
        const panes = this.getPanes();
        panes.overlayMouseTarget.appendChild(this.element);
      }

      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      if (point) {
        this.element.style.left = `${point.x}px`;
        this.element.style.top = `${point.y}px`;
        //this.element.style.transform ='translateX(-50%) translateY(-50%)'
      }
    }

    get latlng() {
      return new google.maps.LatLng(
        this.controller.latitude,
        this.controller.longitude
      )
    }

    getBounds() {
      return new google.maps.LatLngBounds(this.latlng, this.latlng);
    }

    remove() {
      if (this.element) {
        (this.element.parentNode as HTMLElement).removeChild(this.element);
        delete this.element;
      }
    }
    onRemove() {
      this.remove();
    }

  };
}



