import {inject} from "aurelia";
import {Configure} from "./configure";
import {Loader} from "@googlemaps/js-api-loader";
import {MarkerController} from "./interfaces";
import {waitUntil} from "../../drx/utils";
import {customMarkerClassLoader} from "./custom-overlay";

@inject()
export class MapService {

    config: Configure;
    loader: Loader;
    map: google.maps.Map;
    markers: Array<any> = [];


    constructor(config?: Configure) {
        this.config = new Configure();
        this.config.options({
            apiKey: 'AIzaSyC2od38W4lsG4pAF4lCInUvjlBYnyxcb7k',
            apiLibraries: ['places', 'geometry']
        });
        this.loader = new Loader({
            apiKey: this.config.get('apiKey'),
            version: "weekly",
            libraries: this.config.get('apiLibraries')
        });
    }

    public createMap(element: HTMLElement, mapOptions: any): Promise<google.maps.Map> {
        return new Promise((resolve, reject) => {
            this.loader.load().then(() => {
                this.map = new google.maps.Map(element, mapOptions);
                resolve(this.map);
            })
        })
    }


    public addMarkerFromController(markerController: MarkerController) {
        waitUntil(()=> this.map)
            .then(() => {
                const CustomMarkerClass = customMarkerClassLoader();
                const marker: any = new CustomMarkerClass({
                    controller: markerController,
                    element: markerController.markerComponent as HTMLElement,
                    map: this.map,
                });
                this.markers.push(marker);
            })
    }

    public clearAllMarkers() {
        this.markers.forEach((marker) => marker.setMap(null))
        this.markers = [];
    }

    public removeMarkerFromMap(markerController: MarkerController) {
        const marker = this.markers.find((m) => m.controller == markerController)
        if (marker !== undefined) {
            marker.setMap(null);
        }
        this.markers = this.markers.filter((m) => m !== marker);
    }

    public resizeBounds() {
        waitUntil(()=> this.map)
            .then(() => {
                var bounds = new google.maps.LatLngBounds();
                this.markers.forEach(marker => {
                    bounds.extend(marker.latlng)
                })
                this.map.setCenter(bounds.getCenter())
                this.map.fitBounds(bounds);
            })
    }

    public zoomTo(lat: number, lng: number, zoom: number) {
        waitUntil(()=> this.map)
            .then(() => {
                this.map.setZoom(zoom);
                this.map.setCenter({lat: lat, lng: lng});
            })
    }


}
