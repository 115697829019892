/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Museum,
    MuseumFromJSON,
    MuseumFromJSONTyped,
    MuseumToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedMuseumList
 */
export interface PaginatedMuseumList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMuseumList
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMuseumList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMuseumList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Museum>}
     * @memberof PaginatedMuseumList
     */
    results?: Array<Museum>;
}

export function PaginatedMuseumListFromJSON(json: any): PaginatedMuseumList {
    return PaginatedMuseumListFromJSONTyped(json, false);
}

export function PaginatedMuseumListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMuseumList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(MuseumFromJSON)),
    };
}

export function PaginatedMuseumListToJSON(value?: PaginatedMuseumList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(MuseumToJSON)),
    };
}

