/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryToJSON,
    PaginatedCategoryList,
    PaginatedCategoryListFromJSON,
    PaginatedCategoryListToJSON,
    PatchedCategory,
    PatchedCategoryFromJSON,
    PatchedCategoryToJSON,
} from '../models';

export interface CategoriesCreateRequest {
    category: Category;
}

export interface CategoriesDestroyRequest {
    id: number;
}

export interface CategoriesListRequest {
    page?: number;
}

export interface CategoriesPartialUpdateRequest {
    id: number;
    patchedCategory?: PatchedCategory;
}

export interface CategoriesRetrieveRequest {
    id: number;
}

export interface CategoriesUpdateRequest {
    id: number;
    category: Category;
}

/**
 * 
 */
export class CategoriesApi extends runtime.BaseAPI {

    /**
     */
    async categoriesCreateRaw(requestParameters: CategoriesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling categoriesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/categories/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryToJSON(requestParameters.category),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     */
    async categoriesCreate(requestParameters: CategoriesCreateRequest, initOverrides?: RequestInit): Promise<Category> {
        const response = await this.categoriesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesDestroyRaw(requestParameters: CategoriesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling categoriesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async categoriesDestroy(requestParameters: CategoriesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.categoriesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async categoriesListRaw(requestParameters: CategoriesListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedCategoryList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/categories/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCategoryListFromJSON(jsonValue));
    }

    /**
     */
    async categoriesList(requestParameters: CategoriesListRequest, initOverrides?: RequestInit): Promise<PaginatedCategoryList> {
        const response = await this.categoriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesPartialUpdateRaw(requestParameters: CategoriesPartialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling categoriesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedCategoryToJSON(requestParameters.patchedCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     */
    async categoriesPartialUpdate(requestParameters: CategoriesPartialUpdateRequest, initOverrides?: RequestInit): Promise<Category> {
        const response = await this.categoriesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesRetrieveRaw(requestParameters: CategoriesRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling categoriesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     */
    async categoriesRetrieve(requestParameters: CategoriesRetrieveRequest, initOverrides?: RequestInit): Promise<Category> {
        const response = await this.categoriesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesUpdateRaw(requestParameters: CategoriesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling categoriesUpdate.');
        }

        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling categoriesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryToJSON(requestParameters.category),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     */
    async categoriesUpdate(requestParameters: CategoriesUpdateRequest, initOverrides?: RequestInit): Promise<Category> {
        const response = await this.categoriesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
