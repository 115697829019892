import {bindable, customElement, inject} from 'aurelia';
import {Configure} from "./configure";
import {MapService} from "./map-service";

@customElement({name: 'map'})
@inject()
export class MapComponent {

    @bindable service: MapService;
    @bindable longitude: number;
    @bindable latitude: number;
    @bindable zoom: number = 15;
    public map: google.maps.Map;

    constructor(private element: Element,
                private configure: Configure) {
    }

    attaching() {
        this.service.createMap(this.element as HTMLElement, {
            center: {
                lat: parseFloat((<any>this.latitude)),
                lng: parseFloat((<any>this.longitude)),
            },
            zoom: parseFloat((<any>(this.zoom ? this.zoom : 15))),
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            styles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#686868"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "lightness": "-22"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "saturation": "11"
                        },
                        {
                            "lightness": "-51"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "saturation": "3"
                        },
                        {
                            "lightness": "-56"
                        },
                        {
                            "weight": "2.20"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "lightness": "-52"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "weight": "6.13"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "lightness": "-10"
                        },
                        {
                            "gamma": "0.94"
                        },
                        {
                            "weight": "1.24"
                        },
                        {
                            "saturation": "-100"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "lightness": "-16"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": "-41"
                        },
                        {
                            "lightness": "-41"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "weight": "5.46"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "weight": "0.72"
                        },
                        {
                            "lightness": "-16"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "lightness": "-37"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#b7e4f4"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ],
        }).then((map) => this.map = map)
    }


}