import Aurelia from 'aurelia';
import { App } from './app';
import { DialogDefaultConfiguration } from '@aurelia/runtime-html';

import 'whatwg-fetch'

import {MuseumsList} from "./components/museums/list/museums-list";
import {ProjectsList} from "./components/projects/projects-list";
import {MapComponent} from "./components/map/map-component";
import {Swiper} from "./components/swiper/swiper";

const au = new Aurelia();
au.register(MuseumsList)
au.register(ProjectsList)
au.register(MapComponent)
au.register(Swiper)
au.register(DialogDefaultConfiguration.customize(settings => {
    settings.startingZIndex = 500;
}))
au.enhance({
    host: document.querySelector('body'),
    component: App,
})
