import * as __au2ViewDef from './museums-list.html';
import { customElement } from '@aurelia/runtime-html';
import {DrxListComponent} from "../../../drx/abstracts/drx-list-component";
import {Museum} from "../../../api";
import {inject, watch} from "aurelia";
import {MuseumsListController} from "./museums-list-controller";
import {MapService} from "../../map/map-service";


@customElement(__au2ViewDef)
@inject()
export class MuseumsList extends DrxListComponent<Museum, any> {

    mapService: MapService;
    waitingFor = 0;

    constructor(
        public listController: MuseumsListController){
        super()
        this.mapService = new MapService();
        this.listController.listChanged((results) => {
            this.listChanged(results)
        })
    }

    attached() {
        window['theme'].fn.intObsInit( '[data-plugin-toggle]:not(.manual)', 'themePluginToggle' );
    }

    listChanged(newValue) {
        this.waitingFor = newValue.length;
        this.mapService.resizeBounds();

    }

    public notifyReady(){
        this.waitingFor -= this.waitingFor;
    }
}
