import {DrxListController} from "../../../drx/abstracts/drx-list-controller";
import {CategoriesApi, Category, Museum, MuseumsApi, MuseumsListRequest} from "../../../api";
import {inject} from "aurelia";
import {IDrxListResults} from "../../../drx/models";


@inject()
export class MuseumsListController extends DrxListController<any, Museum>{

    categories: Array<Category>

    constructor(private api: MuseumsApi, private categoryApi: CategoriesApi) {
        super();
        this.categoryApi.categoriesList({}).then((res   ) => this.categories = res.results)
    }

    paramsChanged(newParams: any): Promise<IDrxListResults<Museum>> {
        const element = document.getElementById('museumsList')
        if (element) {
            element.scrollIntoView(true);
        }

        return new Promise<IDrxListResults<Museum>>((resolve, reject) => {
            this.api.museumsList(newParams).then((result) => {
                resolve(result);
            }, (error) => {
                reject(error);
            })
        })
    }

    protected resultsChanged(newResults: IDrxListResults<Museum>) {

    }

}
