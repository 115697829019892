/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginatedProjectCategoryList,
    PaginatedProjectCategoryListFromJSON,
    PaginatedProjectCategoryListToJSON,
    PatchedProjectCategory,
    PatchedProjectCategoryFromJSON,
    PatchedProjectCategoryToJSON,
    ProjectCategory,
    ProjectCategoryFromJSON,
    ProjectCategoryToJSON,
} from '../models';

export interface ProjectCategoriesCreateRequest {
    projectCategory?: ProjectCategory;
}

export interface ProjectCategoriesDestroyRequest {
    id: number;
}

export interface ProjectCategoriesListRequest {
    page?: number;
}

export interface ProjectCategoriesPartialUpdateRequest {
    id: number;
    patchedProjectCategory?: PatchedProjectCategory;
}

export interface ProjectCategoriesRetrieveRequest {
    id: number;
}

export interface ProjectCategoriesUpdateRequest {
    id: number;
    projectCategory?: ProjectCategory;
}

/**
 * 
 */
export class ProjectCategoriesApi extends runtime.BaseAPI {

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesCreateRaw(requestParameters: ProjectCategoriesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectCategory>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-categories/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectCategoryToJSON(requestParameters.projectCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectCategoryFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesCreate(requestParameters: ProjectCategoriesCreateRequest, initOverrides?: RequestInit): Promise<ProjectCategory> {
        const response = await this.projectCategoriesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesDestroyRaw(requestParameters: ProjectCategoriesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectCategoriesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesDestroy(requestParameters: ProjectCategoriesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.projectCategoriesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesListRaw(requestParameters: ProjectCategoriesListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedProjectCategoryList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-categories/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedProjectCategoryListFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesList(requestParameters: ProjectCategoriesListRequest, initOverrides?: RequestInit): Promise<PaginatedProjectCategoryList> {
        const response = await this.projectCategoriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesPartialUpdateRaw(requestParameters: ProjectCategoriesPartialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectCategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectCategoriesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedProjectCategoryToJSON(requestParameters.patchedProjectCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectCategoryFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesPartialUpdate(requestParameters: ProjectCategoriesPartialUpdateRequest, initOverrides?: RequestInit): Promise<ProjectCategory> {
        const response = await this.projectCategoriesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesRetrieveRaw(requestParameters: ProjectCategoriesRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectCategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectCategoriesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectCategoryFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesRetrieve(requestParameters: ProjectCategoriesRetrieveRequest, initOverrides?: RequestInit): Promise<ProjectCategory> {
        const response = await this.projectCategoriesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesUpdateRaw(requestParameters: ProjectCategoriesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectCategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectCategoriesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectCategoryToJSON(requestParameters.projectCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectCategoryFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project categories to be viewed or edited.
     */
    async projectCategoriesUpdate(requestParameters: ProjectCategoriesUpdateRequest, initOverrides?: RequestInit): Promise<ProjectCategory> {
        const response = await this.projectCategoriesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
