/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Museum
 */
export interface Museum {
    /**
     * 
     * @type {number}
     * @memberof Museum
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    readonly getAbsoluteUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof Museum
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Museum
     */
    fullyWheelchairAccessible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Museum
     */
    partiallyWheelchairAccessible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Museum
     */
    parking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Museum
     */
    publicTransportationAccessible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Museum
     */
    restaurant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Museum
     */
    cafeteria?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Museum
     */
    shop?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Museum
     */
    childrenAndFamilyFriendly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Museum
     */
    isMuseumAssociationMember?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Museum
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Museum
     */
    longitude?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Museum
     */
    categories?: Array<number>;
}

export function MuseumFromJSON(json: any): Museum {
    return MuseumFromJSONTyped(json, false);
}

export function MuseumFromJSONTyped(json: any, ignoreDiscriminator: boolean): Museum {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'getAbsoluteUrl': json['get_absolute_url'],
        'name': json['name'],
        'slug': json['slug'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'shortDescription': !exists(json, 'short_description') ? undefined : json['short_description'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'fullyWheelchairAccessible': !exists(json, 'fully_wheelchair_accessible') ? undefined : json['fully_wheelchair_accessible'],
        'partiallyWheelchairAccessible': !exists(json, 'partially_wheelchair_accessible') ? undefined : json['partially_wheelchair_accessible'],
        'parking': !exists(json, 'parking') ? undefined : json['parking'],
        'publicTransportationAccessible': !exists(json, 'public_transportation_accessible') ? undefined : json['public_transportation_accessible'],
        'restaurant': !exists(json, 'restaurant') ? undefined : json['restaurant'],
        'cafeteria': !exists(json, 'cafeteria') ? undefined : json['cafeteria'],
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'childrenAndFamilyFriendly': !exists(json, 'children_and_family_friendly') ? undefined : json['children_and_family_friendly'],
        'isMuseumAssociationMember': !exists(json, 'is_museum_association_member') ? undefined : json['is_museum_association_member'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
    };
}

export function MuseumToJSON(value?: Museum | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'slug': value.slug,
        'email': value.email,
        'address': value.address,
        'zip_code': value.zipCode,
        'city': value.city,
        'state': value.state,
        'short_description': value.shortDescription,
        'description': value.description,
        'url': value.url,
        'image': value.image,
        'fully_wheelchair_accessible': value.fullyWheelchairAccessible,
        'partially_wheelchair_accessible': value.partiallyWheelchairAccessible,
        'parking': value.parking,
        'public_transportation_accessible': value.publicTransportationAccessible,
        'restaurant': value.restaurant,
        'cafeteria': value.cafeteria,
        'shop': value.shop,
        'children_and_family_friendly': value.childrenAndFamilyFriendly,
        'is_museum_association_member': value.isMuseumAssociationMember,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'categories': value.categories,
    };
}

