import {IDrxListParams, IDrxListResults} from "../models";
import {getSafe, setSafe} from "../utils";


interface ListChangedListener<TListItem> {
    (results: Array<TListItem>): void;
}

export abstract class DrxListController<TListParams extends IDrxListParams, TListItem> {
    list: Array<TListItem> = [];
    public params: TListParams;
    results: IDrxListResults<TListItem>
    public loading: boolean = false;
    public context: {extra_params: any};

    public update = false;
    private default_limit = 20;
    private current_url: string;
    private listChangedListeners: Array<ListChangedListener<TListItem>> = [];

    abstract paramsChanged(newParams: TListParams): Promise<IDrxListResults<TListItem>>;

    notifyListChangedListeners() {
        this.listChangedListeners.forEach(listener => listener(this.list));
    }

    public listChanged(listener: ListChangedListener<TListItem>) {
        this.listChangedListeners.push(listener);
    }

    public setParam(key, value) {
        setSafe(this.params, key, value);
        this.params.page = 1;
        this.navigateTo(this.params);
    }
    
    public removeParam(key) {
        this.params[key] = undefined;
        this.navigateTo(this.params)
    }

    public async updateParams(queryParams: any) {
        this.loading = true;
        const params: {} = {}
        Object.keys(queryParams).forEach((k) => {
            if (queryParams[k] !== undefined) {
                params[k] = queryParams[k];
            }
        })

        this.current_url = window.location.href;
        this.params = params as any;
        this.results = await this.paramsChanged(this.getExtraParams(this.params));
        this.list = this.results.results;
        this.resultsChanged(this.results);
        this.update = !this.update;
        this.loading = false;
        this.notifyListChangedListeners()
    }

    private getExtraParams(params: any) {
        if (this.context && this.context.extra_params) {
            return Object.assign(params, this.context.extra_params);
        }
        return params;
    }

    protected resultsChanged(newResults: IDrxListResults<TListItem>): void {
        noop();
    }

    refresh() {
        const params = Object.assign({}, this.params)
        this.updateParams(params);
    }

    public clear() {
        this.params = null;
        this.list = [];
    }

    generateUrl(params):string {
        const filtered_params: any = {}
        params = {...Object.assign({}, this.params), ...params};
        if (params.page == 1) {
            params.page = null;
        }
        Object.keys(params).forEach((k) => {
            if (params[k] !== undefined && params[k] !== null && params[k] !== '') {
                filtered_params[k] = params[k];
            }
        })
        let query_string = Object.keys(filtered_params)
            .map(key => key + '=' + filtered_params[key])
            .join('&');
        if (query_string) {
            query_string = `?${query_string}`
        }
        this.current_url = this.current_url.split('?')[0];
        return `${this.current_url}${query_string}`;
    }

    public generateUrlForPage(page_number: number): string {
        let params = {...Object.assign({}, this.params), ...{page: String(page_number)}};
        const query_string = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');
        this.current_url = this.current_url.split('?')[0];
        return `${this.current_url}?${query_string}`;
    }

    public navigateTo(param: any) {
        const url = this.generateUrl(param);
        if (url !== window.location.href) {
            window.history.pushState(param, '', url)
        }
        this.updateParams(this.params);
    }

    get limit(): number {
        return getSafe(this.params, 'limit', this.default_limit);
    }

    set limit(limit) {
        setSafe(this.params, 'limit', limit);
    }

    get order(): string {
        return getSafe(this.params, 'order', undefined);
    }

    set order(order: string) {
        setSafe(this.params, 'order', order);
    }

    get page(): number {
        return getSafe(this.params, 'page', 1);
    }

    get page_start(): number {
        return ((Number(this.page) - 1) * Number(this.limit)) + 1;
    }

    get page_end(): number {
        return (this.page_start + Number(this.count)) -1;
    }

    get count(): number {
        return getSafe(this.list, 'length', 0);
    }

    get total_count(): number {
        return getSafe(this.results, 'count', 0);
    }

    get previous(): string {
        const prev = getSafe(this.results, 'previous', false)
        if (prev) {
            return this.generateUrlForPage(Number(this.page) - 1);
        }

        return null;
    }

    get next(): string {
        const next = getSafe(this.results, 'next', false)
        if (next) {
            return this.generateUrlForPage(Number(this.page) + 1);
        }
        return undefined;
    }
    
}


type Noop = () => void;
// tslint:disable-next-line:no-empty
const noop: Noop = () => {return null;};