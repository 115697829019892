import {DrxListController} from "../../drx/abstracts/drx-list-controller";
import {
    Project,
    ProjectCategoriesApi,
    ProjectCategory,
    ProjectClient,
    ProjectClientsApi,
    ProjectLocation, ProjectLocationsApi,
    ProjectsApi
} from "../../api";
import {IDrxListResults} from "../../drx/models";
import {inject} from "aurelia";


@inject()
export class ProjectsListController extends DrxListController<any, Project>{

    clients: Array<ProjectClient>
    categories: Array<ProjectCategory>
    locations: Array<ProjectLocation>

    constructor(private api: ProjectsApi,
                private categoryApi: ProjectCategoriesApi,
                private clientsApi: ProjectClientsApi,
                private locationApi: ProjectLocationsApi,
                ) {
        super();
        this.categoryApi.projectCategoriesList({}).then(res => this.categories = res.results);
        this.clientsApi.projectClientsList({}).then(res => this.clients = res.results);
        this.locationApi.projectLocationsList({}).then(res => this.locations = res.results);
    }

    paramsChanged(newParams: any): Promise<IDrxListResults<Project>> {
        return new Promise<IDrxListResults<Project>>((resolve, reject) => {
            this.api.projectsList(newParams).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }


}