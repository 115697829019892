export interface ConfigInterface {
    apiKey: string;
    client?: string;
    apiLibraries: Array<string>;
    language?: string;
    region?: string;
}


export class Configure {
    private _config: any;

    constructor() {
        this._config = {
            apiScript: 'https://maps.googleapis.com/maps/api/js',
            apiKey: '',
            apiLibraries: '',
            region: '',
            language: '',
        }
    }

    options(obj: ConfigInterface) {
        Object.assign(this._config, obj);
    }

    get(key: string) {
        return this._config[key];
    }

    set(key: string, val: any) {
        this._config[key] = val;
        return this._config[key];
    }
}
