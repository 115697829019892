import * as __au2ViewDef from './museums-list-item.html';
import { customElement } from '@aurelia/runtime-html';
import {MarkerController} from "../../map/interfaces";
import {bindable} from "aurelia";
import {Museum} from "../../../api";
import {MapService} from "../../map/map-service";
import {MuseumsList} from "./museums-list";

@customElement(__au2ViewDef)
export class MuseumsListItem implements MarkerController {

    draggable: boolean = false;
    @bindable() latitude: number;
    @bindable() longitude: number;
    @bindable() museum: Museum;
    @bindable() mapService: MapService;
    @bindable() index: number;

    marker: google.maps.OverlayView;
    markerComponent: Element;
    hover: boolean = false;
    reveal: boolean = false
    activeTab: string = 'adresse';
    cardComponent: Element;
    zoomedToMarker: boolean = false;


    markerFinishedMoving(): void {
    }

    attaching() {
        this.mapService.addMarkerFromController(this);
        document.addEventListener('tab-will-open', (e) => {
            this.receivedTabWillOpenNotification(e as CustomEvent)
        })
    }

    detaching() {
        this.mapService.removeMarkerFromMap(this);
    }

    highlightMarker(hightlight) {
        this.hover = hightlight;
    }

    markerClicked() {
        this.notifyTabWillOpen();
        this.reveal = true;
        this.cardComponent.scrollIntoView({behavior: "smooth", block: "nearest",  inline: "nearest"});
    }

    zoomToMarker() {
        if(!this.zoomedToMarker) {
            this.notifyTabWillOpen();
            this.mapService.zoomTo(this.latitude, this.longitude, 12);
            this.reveal = true;
            this.zoomedToMarker = true;
        } else {
            this.zoomedToMarker = false;
            this.mapService.resizeBounds();
        }

    }

    close() {
        this.reveal = false;
    }

    showTab(tabName: string) {
        this.notifyTabWillOpen();
        this.activeTab = tabName;
    }

    notifyTabWillOpen() {
        const event = new CustomEvent('tab-will-open', {
            detail: {
                museum: this.museum,
            }
        });
        document.dispatchEvent(event);
    }

    receivedTabWillOpenNotification(e: CustomEvent) {
        if (e.detail.museum !== this.museum) {
            this.reveal = false;
            this.zoomedToMarker = false;
        }
    }

    goToDetail(url) {
        window.location = url;
    }

}
