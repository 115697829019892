<div class="project-detail-modal">
    <div class="close">
        <i click.trigger="close()" class="fas fa-times"></i>
    </div>

    <swiper ref="swiper" autoplay="true">
        <template au-slot="default">
            <img class="slide swiper-slide rounded"
                 repeat.for="image of project.gallery"
                 src.bind="image.url" alt="">
        </template>
    </swiper>

    <div class="body">
        <div class="row">
            <div class="col-auto">
                <p class="mb-0">${project.client.name}</p>
            </div>
            <div class="col-auto">
                <p class="mb-0">${project.location.name}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-auto">
                <h1>${project.name}</h1>
            </div>
        </div>

        <div class="description-text mt-1 mb-3"
             innerhtml.bind="project.description">
        </div>

    </div>
</div>

