/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedProjectClient
 */
export interface PatchedProjectClient {
    /**
     * 
     * @type {number}
     * @memberof PatchedProjectClient
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectClient
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectClient
     */
    readonly description?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedProjectClient
     */
    readonly image?: string;
}

export function PatchedProjectClientFromJSON(json: any): PatchedProjectClient {
    return PatchedProjectClientFromJSONTyped(json, false);
}

export function PatchedProjectClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedProjectClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function PatchedProjectClientToJSON(value?: PatchedProjectClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

