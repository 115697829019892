<import from="./museums-list-filter"></import>
<import from="./museums-list-item"></import>

<section class="section content-map ${listController.loading? 'loading':''}">
    <div id="museumsList" class="container">
        <div class="row position-relative">
            <div class="col-lg-4 px-0">
                <div class="museums-list card-group">
                    <museums-list-item museum.bind="museum"
                                       index.bind="$index"
                                       map-service.bind="mapService"
                                       latitude.bind="museum.latitude"
                                       longitude.bind="museum.longitude"
                                       repeat.for="museum of listController.list">
                    </museums-list-item>
                </div>
            </div>

            <div class="col-lg-8">
                <div class="map-container">
                    <museums-list-filter></museums-list-filter>
                    <map service.bind="mapService"
                         latitude="46.8182"
                         longitude="8.2275"
                         zoom="8">
                    </map>
                </div>
            </div>
        </div>
    </div>
</section>


