import {IDjangoField, IDjangoModel} from "./models";

export function getSchema(url: string): any {
    return new Promise((resolve, reject) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')
        headers.append('X-CSRFToken', getCookie("csrftoken"))
        const init = {
            method: 'GET',
            headers: headers,
        };
        fetch(url, init)
            .then(response => response.json())
            .then(
                (response) => {
                    resolve(response)
                }, (error) => {
                    reject(error);
                })
    })
}

export function getOptions(url: string): Promise<IDjangoField | IDjangoModel> {
    return new Promise((resolve, reject) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')
        headers.append('X-CSRFToken', getCookie("csrftoken"))
        const init = {
            method: 'OPTIONS',
            headers: headers,
        };
        fetch(url, init)
            .then(response => response.json())
            .then(
                (response) => {
                    resolve(response.actions.POST)
                }, (error) => {
                    reject(error);
                })
    })
}
export function getSafe(obj: any, path: string, def?: any ): any {
    if (obj == undefined) {
        return def;
    }

    const paths = path.split('.');
    const ret = paths.reduce((object, path) => {
        return (object || {})[path];
    }, obj);

    if (ret === undefined) {
        return def;
    }

    return ret;
}

export function setSafe(obj: any, path: string, value: any): void {

    if (obj === null || obj === undefined) {
        return;
    }

    try {
        const paths = path.split('.');
        const parent_paths = paths.slice(0, -1);

        const parent = parent_paths.reduce((object, path) => {
            return (object || {})[path];
        }, obj);

        parent[paths[paths.length - 1]] = value;

    } catch (e) {
        console.log(e)
    }
}

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie != '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();

            if (cookie.substring(0, name.length + 1) == (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length +1));
                break;
            }
        }
    }
    return cookieValue;
}

export function waitUntil(condition, timeout=1000) {
    const promise = new Promise((resolve, reject) => {
        const reject_timeout = setTimeout(function(){
            console.log('timedout');
            reject();
        }, timeout);
        function loop() {
            if (condition()){
                clearTimeout(reject_timeout);
                return resolve(true)
            }
            setTimeout(loop, 10)
        }
        setTimeout(loop, 10)
    });

    return promise;
}