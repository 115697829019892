/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginatedProjectList,
    PaginatedProjectListFromJSON,
    PaginatedProjectListToJSON,
    PatchedProject,
    PatchedProjectFromJSON,
    PatchedProjectToJSON,
    Project,
    ProjectFromJSON,
    ProjectToJSON,
} from '../models';

export interface ProjectsCreateRequest {
    project: Project;
}

export interface ProjectsDestroyRequest {
    id: number;
}

export interface ProjectsListRequest {
    category?: string;
    client?: string;
    location?: string;
    name?: string;
    page?: number;
}

export interface ProjectsPartialUpdateRequest {
    id: number;
    patchedProject?: PatchedProject;
}

export interface ProjectsRetrieveRequest {
    id: number;
}

export interface ProjectsUpdateRequest {
    id: number;
    project: Project;
}

/**
 * 
 */
export class ProjectsApi extends runtime.BaseAPI {

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsCreateRaw(requestParameters: ProjectsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling projectsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.project),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsCreate(requestParameters: ProjectsCreateRequest, initOverrides?: RequestInit): Promise<Project> {
        const response = await this.projectsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsDestroyRaw(requestParameters: ProjectsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsDestroy(requestParameters: ProjectsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.projectsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsListRaw(requestParameters: ProjectsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedProjectList>> {
        const queryParameters: any = {};

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.location !== undefined) {
            queryParameters['location'] = requestParameters.location;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedProjectListFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsList(requestParameters: ProjectsListRequest, initOverrides?: RequestInit): Promise<PaginatedProjectList> {
        const response = await this.projectsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsPartialUpdateRaw(requestParameters: ProjectsPartialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedProjectToJSON(requestParameters.patchedProject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsPartialUpdate(requestParameters: ProjectsPartialUpdateRequest, initOverrides?: RequestInit): Promise<Project> {
        const response = await this.projectsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsRetrieveRaw(requestParameters: ProjectsRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsRetrieve(requestParameters: ProjectsRetrieveRequest, initOverrides?: RequestInit): Promise<Project> {
        const response = await this.projectsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsUpdateRaw(requestParameters: ProjectsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectsUpdate.');
        }

        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling projectsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/projects/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.project),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows projects to be viewed or edited.
     */
    async projectsUpdate(requestParameters: ProjectsUpdateRequest, initOverrides?: RequestInit): Promise<Project> {
        const response = await this.projectsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
