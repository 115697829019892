<li class="list-group-item list-group-item-action ${hover? 'hovering': ''}" click.trigger="zoomToMarker()"
    ref="cardComponent"
    mouseover.trigger="highlightMarker(true)"
    mouseleave.trigger="highlightMarker(false)">
    <div class="image"><img src="${ project.client.image }">
    </div>
    <div class="infos">
        <span>${ project.name }</span>
        <span>${ project.location.name }</span>
    </div>
    <div class="more-infos  mt-2 d-flex">
                    <span click.trigger="openDetails()"
                          class="fas fa-info-circle">
                    </span>
    </div>
</li>


<div class="map-overlay drf-marker ${hover? 'hover': ''}"
     mouseover.trigger="highlightMarker(true)"
     mouseleave.trigger="highlightMarker(false)"
     click.trigger="markerClicked()"
     ref="markerComponent">
    <i class="mach-marker  ${hover? 'selected': 'normal'}"></i>
    <div class="anchor ${reveal? 'reveal':''}" ref="anchor">
        <div class="info-component-project shadow rounded">
            <div class="header">
                <i click.delegate="close()" class="fas fa-times"></i>

                <swiper mousedown.trigger="passToSwiper($event)" ref="swiper">
                    <template au-slot="default">
                        <img class="slide swiper-slide rounded" repeat.for="image of project.gallery"
                             src.bind="image.url" alt="">
                    </template>

                </swiper>

                <div class="client shadow rounded">
                    <strong repeat.for="category of project.categories">${ category.name }</strong>
                </div>

            </div>
            <div class="body">
                <span class="category" >${ project.client.name }</span><br>
                <h3>${ project.name }</h3>
                <span class="location text-4">${ project.location.name }</span><br>
                <div class="more-infos mt-2 d-flex">
                    <span click.trigger="openDetails()"
                          class="fas fa-info-circle">
                    </span>
                </div>
            </div>
        </div>
    </div>

</div>