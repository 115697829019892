/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectLocation
 */
export interface ProjectLocation {
    /**
     * 
     * @type {number}
     * @memberof ProjectLocation
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocation
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectLocation
     */
    readonly description: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectLocation
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectLocation
     */
    longitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectLocation
     */
    zoom?: number | null;
}

export function ProjectLocationFromJSON(json: any): ProjectLocation {
    return ProjectLocationFromJSONTyped(json, false);
}

export function ProjectLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'zoom': !exists(json, 'zoom') ? undefined : json['zoom'],
    };
}

export function ProjectLocationToJSON(value?: ProjectLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
        'zoom': value.zoom,
    };
}

