/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginatedProjectLocationList,
    PaginatedProjectLocationListFromJSON,
    PaginatedProjectLocationListToJSON,
    PatchedProjectLocation,
    PatchedProjectLocationFromJSON,
    PatchedProjectLocationToJSON,
    ProjectLocation,
    ProjectLocationFromJSON,
    ProjectLocationToJSON,
} from '../models';

export interface ProjectLocationsCreateRequest {
    projectLocation?: ProjectLocation;
}

export interface ProjectLocationsDestroyRequest {
    id: number;
}

export interface ProjectLocationsListRequest {
    page?: number;
}

export interface ProjectLocationsPartialUpdateRequest {
    id: number;
    patchedProjectLocation?: PatchedProjectLocation;
}

export interface ProjectLocationsRetrieveRequest {
    id: number;
}

export interface ProjectLocationsUpdateRequest {
    id: number;
    projectLocation?: ProjectLocation;
}

/**
 * 
 */
export class ProjectLocationsApi extends runtime.BaseAPI {

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsCreateRaw(requestParameters: ProjectLocationsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectLocation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-locations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectLocationToJSON(requestParameters.projectLocation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectLocationFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsCreate(requestParameters: ProjectLocationsCreateRequest, initOverrides?: RequestInit): Promise<ProjectLocation> {
        const response = await this.projectLocationsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsDestroyRaw(requestParameters: ProjectLocationsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectLocationsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-locations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsDestroy(requestParameters: ProjectLocationsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.projectLocationsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsListRaw(requestParameters: ProjectLocationsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedProjectLocationList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-locations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedProjectLocationListFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsList(requestParameters: ProjectLocationsListRequest, initOverrides?: RequestInit): Promise<PaginatedProjectLocationList> {
        const response = await this.projectLocationsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsPartialUpdateRaw(requestParameters: ProjectLocationsPartialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectLocation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectLocationsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-locations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedProjectLocationToJSON(requestParameters.patchedProjectLocation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectLocationFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsPartialUpdate(requestParameters: ProjectLocationsPartialUpdateRequest, initOverrides?: RequestInit): Promise<ProjectLocation> {
        const response = await this.projectLocationsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsRetrieveRaw(requestParameters: ProjectLocationsRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectLocation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectLocationsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-locations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectLocationFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsRetrieve(requestParameters: ProjectLocationsRetrieveRequest, initOverrides?: RequestInit): Promise<ProjectLocation> {
        const response = await this.projectLocationsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsUpdateRaw(requestParameters: ProjectLocationsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectLocation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectLocationsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/project-locations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectLocationToJSON(requestParameters.projectLocation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectLocationFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows project locations to be viewed or edited.
     */
    async projectLocationsUpdate(requestParameters: ProjectLocationsUpdateRequest, initOverrides?: RequestInit): Promise<ProjectLocation> {
        const response = await this.projectLocationsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
