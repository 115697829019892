import * as __au2ViewDef from './museums-list-filter.html';
import { customElement } from '@aurelia/runtime-html';
import {inject} from "aurelia";
import {MuseumsListController} from "./museums-list-controller";
import {listFilterParams} from "../../../drx/abstracts/decorators";
import {Category} from "../../../api";


@customElement(__au2ViewDef)
@inject()
export class MuseumsListFilter {

    activeCategory: Category;

    @listFilterParams() name: string;
    @listFilterParams() location: string;
    @listFilterParams({default: false, hint: 'boolean'}) fullyWheelchairAccessible
    @listFilterParams({default: false, hint: 'boolean'}) partiallyWheelchairAccessible
    @listFilterParams({default: false, hint: 'boolean'}) parking
    @listFilterParams({default: false, hint: 'boolean'}) publicTransportationAccessible
    @listFilterParams({default: false, hint: 'boolean'}) restaurant
    @listFilterParams({default: false, hint: 'boolean'}) cafeteria
    @listFilterParams({default: false, hint: 'boolean'}) shop
    @listFilterParams({default: false, hint: 'boolean'}) childrenAndFamilyFriendly
    @listFilterParams({default: false, hint: 'boolean'}) isMuseumAssociationMember
    @listFilterParams() category: number;

    constructor(private listController: MuseumsListController) {}

    setCategory(category: Category) {
        if (category !== undefined) {
            this.activeCategory = category
            this.category = category.id;
        } else {
            this.activeCategory = undefined;
            this.category = undefined;
        }

    }

    clearName() {
        this.name = undefined;
    }

}

/*

    cafeteria?: boolean;
    categories?: Array<number>;
    childrenAndFamilyFriendly?: boolean;
    fullyWheelchairAccessible?: boolean;
    isMuseumAssociationMember?: boolean;
    location?: string;
    name?: string;
    page?: number;
    parking?: boolean;
    partiallyWheelchairAccessible?: boolean;
    publicTransportationAccessible?: boolean;
    restaurant?: boolean;
    shop?: boolean;

 */
