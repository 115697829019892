import {DrxListController} from "./drx-list-controller";
import {watch} from "aurelia";

export abstract class DrxListComponent<TListItem, TListController extends DrxListController<any, TListItem>> {

    list: Array<TListItem>;
    abstract listController: TListController;
    loading = true;
    showFilters = false;
    window = window;

    bound() {
        this.listController.updateParams(this.getParamsFromUrl()).then(r => r);
    }

    getParamsFromUrl(): any {
        if(!window.location.search) {
            return {}
        }
        return decodeURI(window.location.search)
            .replace('?', '')
            .split('&')
            .map(param => param.split('='))
            .reduce((values, [ key, value ]) => {
                values[ key ] = value
                return values
            }, {});
    }

    refresh() {
        this.listController.refresh();
    }


}