import * as __au2ViewDef from './projects-list.html';
import { customElement } from '@aurelia/runtime-html';
import {DrxListComponent} from "../../drx/abstracts/drx-list-component";
import {Category, Project, ProjectCategory, ProjectClient, ProjectClientsApi, ProjectLocation} from "../../api";
import {ProjectsListController} from "./projects-list-controller";
import {inject} from "aurelia";
import {MapService} from "../map/map-service";
import {listFilterParams} from "../../drx/abstracts/decorators";


@customElement(__au2ViewDef)
@inject()
export class ProjectsList extends DrxListComponent<Project, ProjectsListController> {

    activeCategory: ProjectCategory;
    activeLocation: ProjectLocation;
    activeClient: ProjectClient;

    @listFilterParams() name: string;
    @listFilterParams() location: number;
    @listFilterParams() category: number;
    @listFilterParams() client: number;

    mapService: MapService;
    showMap  = false;

    constructor(
        public listController: ProjectsListController) {
        super();
        this.mapService = new MapService();
        this.listController.listChanged((results) => {
            this.listChanged(results)
        })
    }

    listChanged(newValue) {
        this.mapService.resizeBounds();
    }

    setCategory(category: ProjectCategory) {
        if (category !== undefined) {
            this.activeCategory = category
            this.category = category.id;
        } else {
            this.activeCategory = undefined;
            this.category = undefined;
        }
    }
    setLocation(location: ProjectLocation) {
        if (location !== undefined) {
            this.activeLocation= location
            this.location = location.id;
        } else {
            this.activeLocation = undefined;
            this.location = undefined;
        }
    }
    setClient(client: ProjectClient) {
        if (client !== undefined) {
            this.activeClient = client
            this.client = client.id;
        } else {
            this.activeClient = undefined;
            this.client = undefined;
        }
    }

    clearName() {
        this.name = undefined;
    }

    visibilityMap() {
        this.showMap = !this.showMap
    }
}
