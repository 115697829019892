<div class="filters">
    <div class="row nowrap">
        <div class="item">
            <label class="form-label mb-1 text-2 ">Museumsname</label>
            <div class="input-group">
                <div class="input-group-append">
                    <button class="btn btn-secondary" type="button">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                <input type="text"
                       value.bind="name"
                       class="form-control"
                       placeholder="Museumsname">
                <button type="button" class="close" aria-label="Close" click.delegate="clearName()">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>
        </div>
        <div class="item">
            <div class="form-group">
                <label class="form-label mb-1 text-2">Ort</label>
                <input type="text"
                       value.bind="location"
                       placeholder="PLZ, Ort oder Kanton"
                       class="form-control text-3 h-auto py-2"
                       name="name">
            </div>
        </div>
        <div class="item">
            <label class="form-label mb-1 text-2">Kategorie</label>
            <div class="dropdown">

                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdown-categorie"
                        data-bs-toggle="dropdown" aria-expanded="false">

                    <span if.bind="activeCategory">${activeCategory.name}</span>
                    <span else>Kategorie</span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="Kategorie">
                    <li class="dropdown-item" click.delegate="setCategory()">Keine Kategorie</li>
                    <li class="dropdown-item" click.delegate="setCategory(category)" repeat.for="category of listController.categories">
                        <span>${category.name}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="mt-0 toggle toggle-primary toggle-simple" data-plugin-toggle>
            <section class="toggle">
                <a class="toggle-title">Kriterien</a>
                <div class="toggle-content" style="display: none;">
                    <div class="row">
                        <div class="col-lg-4">
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                               checked.bind="fullyWheelchairAccessible"
                                               class="custom-control-input form-check-input me-1"
                                               id="fullyWheelchairAccessible">
                                        <label class="custom-control-label text-2" for="fullyWheelchairAccessible">
                                            vollständige Rollstuhlgängigkeit
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                               checked.bind="partiallyWheelchairAccessible"
                                               class="custom-control-input form-check-input me-1"
                                               id="partiallyWheelchairAccessible">
                                        <label class="custom-control-label text-2" for="partiallyWheelchairAccessible">
                                            teilweise Rollstuhlzugänglich
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" checked.bind="publicTransportationAccessible"
                                               class="custom-control-input form-check-input me-1" id="publicTransportationAccessible">
                                        <label class="custom-control-label text-2" for="publicTransportationAccessible">
                                           Sehr gut mit den ÖV's erreichbar
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-4">
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" checked.bind="parking"
                                               class="custom-control-input form-check-input me-1" id="parking">
                                        <label class="custom-control-label text-2" for="parking">
                                            Parkmöglichkeiten
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                               checked.bind="restaurant"
                                               class="custom-control-input form-check-input me-1"
                                               id="restaurant">
                                        <label class="custom-control-label text-2 " for="restaurant">
                                            Restaurant
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                               checked.bind="cafeteria"
                                               class="custom-control-input form-check-input me-1"
                                               id="cafeteria">
                                        <label class="custom-control-label text-2 " for="cafeteria">
                                            Cafeteria
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-4">
                            <ul  class="list-group">
                                <li class="list-group-item">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" checked.bind="shop" class="custom-control-input form-check-input me-1"
                                               id="shop">
                                        <label class="custom-control-label text-2 " for="shop">
                                            Shop
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" checked.bind="childrenAndFamilyFriendly"
                                               class="custom-control-input form-check-input me-1" id="childrenAndFamilyFriendly">
                                        <label class="custom-control-label text-2 " for="childrenAndFamilyFriendly">
                                            Kinder und Familienfreundlich
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" checked.bind="isMuseumAssociationMember"
                                               class="custom-control-input form-check-input me-1" id="isMuseumAssociationMember">
                                        <label class="custom-control-label text-2" for="isMuseumAssociationMember">
                                            Schweizer Museumsverband
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
