import * as __au2ViewDef from './project-list-item.html';
import { customElement } from '@aurelia/runtime-html';
import {Museum, Project} from "../../api";
import {bindable, DialogDeactivationStatuses, DialogService, IDialogService, inject} from "aurelia";
import {MapService} from "../map/map-service";
import {MarkerController} from "../map/interfaces";
import {ProjectDetail} from "./project-detail";


@customElement(__au2ViewDef)
export class ProjectListItem implements MarkerController {

    static inject = [IDialogService];

    @bindable project: Project;
    @bindable() latitude: number;
    @bindable() longitude: number;
    @bindable() mapService: MapService;
    @bindable() index: number;

    draggable = true;
    marker: google.maps.OverlayView;
    markerComponent: HTMLElement;
    hover = false;
    reveal = false
    activeTab = 'adresse';
    cardComponent: HTMLElement;
    zoomedToMarker = false;
    swiper: HTMLElement;
    dialogService: DialogService;


    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    markerFinishedMoving(): void {
    }

    attaching() {
        this.mapService.addMarkerFromController(this);
        document.addEventListener('tab-will-open', (e) => {
            this.receivedTabWillOpenNotification(e as CustomEvent)
        })
    }

    detaching() {
        this.mapService.removeMarkerFromMap(this);
    }

    highlightMarker(hightlight) {
        this.hover = hightlight;
    }

    markerClicked() {
        this.notifyTabWillOpen();
        this.reveal = true;
        this.cardComponent.scrollIntoView({behavior: "smooth", block: "nearest",  inline: "nearest"});
    }
    zoomToMarker() {
        if(!this.zoomedToMarker) {
            this.notifyTabWillOpen();
            this.mapService.zoomTo(this.latitude, this.longitude, 12);
            this.reveal = true;
            this.zoomedToMarker = true;
        } else {
            this.zoomedToMarker = false;
            this.mapService.resizeBounds();
        }
    }

    close() {
        this.reveal = false;
    }

    showTab(tabName: string) {
        this.notifyTabWillOpen();
        this.activeTab = tabName;
    }

    notifyTabWillOpen() {
        const event = new CustomEvent('tab-will-open', {
            detail: {
                museum: this.project,
            }
        });
        document.dispatchEvent(event);
    }

    receivedTabWillOpenNotification(e: CustomEvent) {
        if (e.detail.project !== this.project) {
            this.reveal = false;
            this.zoomedToMarker = false;
        }
    }

    openDetails() {
        this.dialogService.open({component: () => ProjectDetail, model: this.project})
            .then(openDialogResult => {
                // Note:
                // We get here when the dialog is opened,
                // and we are able to close dialog
                setTimeout(() => {
                    //openDialogResult.dialog.cancel('Failed to finish editing after 3 seconds');
                }, 3000);

                // each dialog controller should expose a promise for attaching callbacks
                // to be executed for when it has been closed
                return openDialogResult.dialog.closed;
            })
            .then((response) => {
                if (response.status === DialogDeactivationStatuses.Ok) {
                    console.log('good');
                } else {
                    console.log('bad');
                }
                console.log(response);
            });
    }

    passToSwiper(e) {
        console.log('clicked')
        e.stopPropagation()
        //this.swiper.dispatchEvent(e);
    }

}
