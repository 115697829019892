/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface SchemaRetrieveRequest {
    format?: SchemaRetrieveFormatEnum;
    lang?: SchemaRetrieveLangEnum;
}

/**
 * 
 */
export class SchemaApi extends runtime.BaseAPI {

    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     */
    async schemaRetrieveRaw(requestParameters: SchemaRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/schema/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     */
    async schemaRetrieve(requestParameters: SchemaRetrieveRequest, initOverrides?: RequestInit): Promise<{ [key: string]: any; }> {
        const response = await this.schemaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SchemaRetrieveFormatEnum {
    Json = 'json',
    Yaml = 'yaml'
}
/**
    * @export
    * @enum {string}
    */
export enum SchemaRetrieveLangEnum {
    De = 'de',
    En = 'en',
    Fr = 'fr'
}
