import {getSafe} from "../utils";


interface IListController {
    params: any;
    navigateTo: any;
}


export function listFilterParams(options?: any): any {
    return function (target: {listController: {params: any, navigateTo: any}}, propertyKey: string) {
        const opt = Object.assign({default: undefined, hint: undefined}, options);

        const getter = function() {
            if (opt.hint == 'boolean') {
                if (opt.default == true) {
                    return [true, 'true', 'True'].includes(getSafe(this.listController.params, propertyKey, true));
                } else {
                    return [true, 'true', 'True'].includes(getSafe(this.listController.params, propertyKey, false));
                }
            }

            if (opt.hint == 'tristate-boolean') {
                if ([true, 'true', 'True'].includes(getSafe(this.listController.params, propertyKey))) {
                    return true;
                } else if ([false, 'false', 'False'].includes(getSafe(this.listController.params, propertyKey))) {
                    return false;
                }
            }

            return getSafe(this.listController.params, propertyKey, opt.default);
        }

        const setter = function(newVal: string) {

            if (newVal !== this.listController.params[propertyKey]) {
                let param = {}

                if (opt.hint == 'boolean') {
                    if (opt.default == true && ![true, 'true', 'True'].includes(newVal)) {
                        param[propertyKey] = newVal;
                    } else if(opt.default == false && ![false, 'false', 'False'].includes(newVal)) {
                        param[propertyKey] = newVal;
                    } else {
                        param[propertyKey] = undefined;
                        this.listController.removeParam(propertyKey)
                        return;
                    }
                } else if(opt.hint == 'tristate-boolean') {
                    if (getSafe(this.listController.params, propertyKey) == undefined) {
                        param[propertyKey] = true;
                    } else if ([true, 'true', 'True'].includes(getSafe(this.listController.params, propertyKey))) {
                        param[propertyKey] = false;
                    } else if ([false, 'false', 'False'].includes(getSafe(this.listController.params, propertyKey))) {
                        param[propertyKey] = undefined;
                        this.listController.removeParam(propertyKey)
                        return;
                    }
                } else {
                    if (newVal !== opt.default) {
                        param[propertyKey] = newVal;
                    }
                }

                this.listController.setParam(propertyKey, newVal)
            }
        }

        return Object.defineProperty(target, propertyKey, {
            get: getter,
            set: setter,
            configurable: true,
        })
    }
}